.pag-1btn {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.pag-1btn a {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 5px;
  border-radius: 10px;
  border: 1px;
  color: white;
  background-color: #2a303c;
  cursor: pointer;
}

.pag-1btn a:hover {
  color: white;
  background-color: #191d24;
}

.active-1btn a {
  color: white;
  background-color: #2b2eff;
}

.active-1btn a:hover {
  color: white;
  background-color: #2b2eff;
}

.disabled-1btn a {
  color: #a6adba;
  background-color: #3d444d;
}

.disabled-1btn a:hover {
  color: #a6adba;
  background-color: #3d444d;
}

.root {
  margin: 0;
  padding: 0;
}

.sarana-print {
  display: none;
}
.owner-print {
  display: none;
}
.staff-print {
  display: none;
}

@media print {
  @page {
    size: landscape;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;
  }

  .sarana-display {
    display: none;
  }
  .sarana-print {
    display: flex;
  }
  .owner-display {
    display: none;
  }
  .owner-print {
    display: flex;
  }
  .staff-display {
    display: none;
  }
  .staff-print {
    display: flex;
  }

  .item-print {
    page-break-after: always;
  }

  #item-container {
    display: block;
  }
}

@media print {
  .page-break {
    /* clear: both; */
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 8mm 10mm;
}

@media print {
  .watermark {
    position: fixed;
    top: 50vh;
    z-index: 9;
    width: 50vw;
    page-break-after: always;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
  }
  
  div.page-footer {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 40px;
    font-size: 15px;
    color: #fff;
    opacity: 0.5;
    page-break-after: always;
  }
}

/* @media print {
  .page-break {
    page-break-before: always;
  } /* page-break-after works, as well */
